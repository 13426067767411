<template>
  <v-app>
    <div
      style="position: fixed; height: 100vh; width: 100vw; background-color: #003677"
    />
    <v-container
      id="fundo"
      class="p-0 fill-height"
      fluid
    >
      <v-row
        class="fill-height"
        align="center"
        justify="center"
      >
        <v-col
          cols="11"
          sm="8"
          md="5"
          lg="4"
          xl="3"
          style="padding: 0px"
        >
          <v-container :style="$vuetify.breakpoint.xs ? 'padding: 4px' : ''">
            <v-container class="pb-4">
              <v-row
                class="layout-logos-login fill-height px-4 height"
                align="center"
                justify="center"
              >
                <v-img
                  class="logo-form-login educampo"
                  src="@/assets/logo_branca.png"
                  height="200px"
                  contain
                />
              </v-row>
            </v-container>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
<script>
  export default {
    data () {
      return {
        loading: true,
        message: 'Validando Email',
      }
    },
    created () {
      this.Swal.fire({
        title: 'Verificando e-mail!',
        timerProgressBar: true,
        showConfirmButton: false,
        willOpen: () => {
          this.Swal.showLoading()
        },
        didOpen: () => {
          this.api.verifyMail(this.$route.params.token).then(
            result => {
              this.Swal.fire({
                icon: 'success',
                title: 'E-mail verificado com sucesso!',
                showConfirmButton: false,
                timer: 2000,
                didClose: () => {
                  this.$router.push('/login')
                },
              })
            },
            error => {
              this.Swal.fire({
                icon: 'error',
                title: error.message,
                allowOutsideClick: false,
                didClose: () => {
                  this.$router.push('/login')
                },
              })
            },
          )
        },
      })
    },
  }
</script>
